'use strict';
function addCleaveMasks() {
  function getCleave(element) {
    var type = element.getAttribute('data-mask');

    var phoneOptions = {
      numericOnly: true,
      blocks: [3, 3, 4],
      delimiters: ['-', '-', '-'] };


    var extOptions = {
      numericOnly: true,
      blocks: [6] };


    var zipOptions = {
      numericOnly: true,
      blocks: [5] };


    var dateOptions = {
      date: true,
      datePattern: ['m', 'd', 'Y'] };


    switch (type) {
      case 'phone':
        return new Cleave(element, phoneOptions);
      case 'zip':
        return new Cleave(element, zipOptions);
      case 'date':
        return new Cleave(element, dateOptions);
      case 'ext':
        return new Cleave(element, extOptions);
      default:
      //nothing
    }
  }

  var maskInputs = document.querySelectorAll('[data-mask]');
  for (var i = 0; i < maskInputs.length; i++) {
    getCleave(maskInputs[i]);
  }
}

document.addEventListener('DOMContentLoaded', addCleaveMasks);